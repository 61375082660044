@charset "utf-8";
$htmlFontSize: 100px;

@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}

@function px($values...) {
  $max: length($values);
  $remValues: "";

  @for $i from 1 through $max {
    $item: strip-units(nth($values, $i)) / strip-units($htmlFontSize) * 1rem;
    $remValues: #{$remValues + " " + $item};
  }

  @return $remValues;
}

// 中间定位函数
@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}
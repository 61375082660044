@import "../../styles/common.scss";

.modal-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0 ,0, 0.6);
  z-index: 100;

  color: white;

  .box {
    @include centerer;
    width: 92%;
    display: flex;
    flex-direction: column;

    .header {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      line-height: 60px;
      padding: 0 16px;
      box-sizing: border-box;
      width: 100%;
      height: 60px;
      background-color: #EFEADD;

      .title {
        font-family: MyriadPro-Regular;
        font-size: 14px;
        color: #40210f;
        letter-spacing: 1.4px;
      }

      .close {
        cursor: pointer;
      }
    }

    .content {
      position: relative;
      width: 100%;
      height: 350px;
      background-color: #90816D;

      .center-box{
        @include centerer;
        box-sizing: border-box;
        padding: 0 16px;
        width: 100%;
      }
      
      .line {
        opacity: 0.4;
        background: #40210F;
        height: 1px;
      }

      .lang-english {
        letter-spacing: .025rem;
      }

      .lang-item {
        cursor: pointer;
        width: .9rem;
        margin: 0 auto;
        box-sizing: border-box;
        height: 52px;
        line-height: 52px;
        font-size: 22px;
        color: #40210F;
        font-family: Helvetica;
        text-align: justify;
        text-align-last: justify;
        opacity: .5;

        &.cn {
          display: flex;
          justify-content: space-between;
          font-family: 'SourceHanSans-Regular';
        }
      }

      .active {
        opacity: 1;
      }
    }
  }
  

  .close {
    width: 14px;
    height: 14px;
  }
  
}